function toggleHamburger() {
    const hamburger = document.querySelector('.header__hamburger');
    const hamburgerActiveClass = 'header__hamburger_active';
    const mobile = document.querySelector('.header__mobile');
    const mobileActiveClass = 'header__mobile_active';

    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle(hamburgerActiveClass);
        mobile.classList.toggle(mobileActiveClass);

        if (document.body.style.overflow === "") {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    });
}

export default toggleHamburger;