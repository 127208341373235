function initVideo(videoSelector) {
    const videos = document.querySelectorAll(videoSelector);

    videos.forEach(video => {
        video.addEventListener('click', () => {
            video.setAttribute('controls', 'controls');
        });
    });
}

export default initVideo;