function toggleTabs({
    tabParentSelector, 
    tabHeaderSelector, 
    tabHeaderActiveClass, 
    tabItemSelector, 
    tabItemActiveClass, 
    event}) {
    const tabParent = document.querySelector(tabParentSelector);
    const tabHeaders = document.querySelectorAll(tabHeaderSelector);
    const tabItems = document.querySelectorAll(tabItemSelector);

    tabParent.addEventListener(event, (e) => {
        if (e.target && e.target.closest(tabHeaderSelector)) {
            tabHeaders.forEach((tabHeader, i) => {
                if (e.target === tabHeader || e.target.closest(tabHeaderSelector) === tabHeader) {
                    if (tabHeaderActiveClass) {
                        tabHeaders.forEach(tabItem => {
                            tabItem.classList.remove(tabHeaderActiveClass);
                        })
                        tabHeaders[i].classList.add(tabHeaderActiveClass);
                    }

                    // только если кликаем по табхедеру в хедере
                    if (tabHeaderSelector === '.header .tabheader__item') {
                        const tabContentsWrapper = document.querySelector('.header-dropdown .tabcontent__list-wrapper');
                        const activeClass = 'tabcontent__list-wrapper_active';

                        // то весь выпадающий список в хедере, если не активен
                        if (!tabContentsWrapper.classList.contains(activeClass)) {
                            // становится активным
                            tabContentsWrapper.classList.add(activeClass);

                            // если при активном выпадающем списке уводим из него курсор
                            document.addEventListener('mousemove', (e) => {
                                if (!e.target.closest('.header') && !e.target.closest('.header-dropdown')) {
                                    // то закрываем его
                                    tabContentsWrapper.classList.remove(activeClass);

                                    tabItems.forEach(tabItem => {
                                        tabItem.classList.remove(tabItemActiveClass);
                                    });
                                }
                            });
                        }
                    }

                    tabItems.forEach(tabItem => {
                        tabItem.classList.remove(tabItemActiveClass);
                    });
                    tabItems[i].classList.add(tabItemActiveClass);
                }
            });
        }
    });
}

export default toggleTabs;