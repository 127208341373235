import toggleTabs from './toggleTabs';

// дублирующийся код, который при желании можно оптимизировать
function initTabsInHeader() {
    for (let i = 1; i <= 6; i++) {
        toggleTabs({
            tabParentSelector: `.header-dropdown .tabcontent__item:nth-child(${i}) .info__list`,
            tabHeaderSelector: `.header-dropdown .tabcontent__item:nth-child(${i}) .info__title`,
            tabHeaderActiveClass: 'info__title_active',
            tabItemSelector: `.header-dropdown .tabcontent__item:nth-child(${i}) .info__text-item`,
            tabItemActiveClass: 'info__text-item_active',
            event: 'mouseover'
        });
    }
}

export default initTabsInHeader;