'use strict';
import initVh from '../modules/initVh';
import toggleTabs from '../modules/toggleTabs';
import initTabsInHeader from '../modules/initTabsInHeader';
import initModalsInHeader from '../modules/initModalsInHeader';
import toggleHamburger from '../modules/toggleHamburger';
import createMasks from '../modules/createMasks';
import toggleSelect from '../modules/toggleSelect';
import submitForm from '../modules/submitForm';
import initVideo from '../modules/initVideo';
import { toggleModal } from '../modules/toggleModal';

document.addEventListener('DOMContentLoaded', () => {
    initVh();

    if (document.documentElement.clientWidth >= 1200) {
        toggleTabs({
            tabParentSelector: '.header .tabheader__list',
            tabHeaderSelector: '.header .tabheader__item',
            tabItemSelector: '.header-dropdown .tabcontent__item',
            tabItemActiveClass: 'tabcontent__item_active',
            event: 'mouseover'
        });
    
        initTabsInHeader();
    
        toggleTabs({
            tabParentSelector: `.info__list`,
            tabHeaderSelector: '.info__title',
            tabHeaderActiveClass: 'info__title_active',
            tabItemSelector: '.info__text-item',
            tabItemActiveClass: 'info__text-item_active',
            event: 'mouseover'
        });
    
        // initModalsInHeader();
    } else {
        toggleHamburger();
    }

    // toggleSelect({
    //     selectHeaderSelector: '.modal_calc .select__header', 
    //     selectItemSelector: '.modal_calc .select__item', 
    //     selectCurrentSelector: '.modal_calc .select__current', 
    //     selectActive: 'select_active', 
    //     selectHeaderActive: 'select__header_active',
    //     selectSelector: '.modal_calc .select'
    // });

    // submitForm({
    //     formSelector: '.modal_calc .modal__form', 
    //     inputSelector: '.modal_calc .modal__input', 
    //     modalMainSelector: '.modal_calc',
    //     modalThanksSelector: '.modal_thanks', 
    //     modalErrorSelector: '.modal_error', 
    //     modalActiveClass: 'modal_active',
    //     url: 'mailer/calculation.php'
    // });
    
    createMasks('[data-phone]', {
        mask: '+{7} (000) 000-00-00',
    });

    // toggleSelect({
    //     selectHeaderSelector: '.modal_ad .select__header', 
    //     selectItemSelector: '.modal_ad .select__item', 
    //     selectCurrentSelector: '.modal_ad .select__current', 
    //     selectActive: 'select_active', 
    //     selectHeaderActive: 'select__header_active',
    //     selectSelector: '.modal_ad .select'
    // });

    // submitForm({
    //     formSelector: '.modal_ad .modal__form', 
    //     inputSelector: '.modal_ad .modal__input', 
    //     modalMainSelector: '.modal_ad', 
    //     modalThanksSelector: '.modal_thanks', 
    //     modalErrorSelector: '.modal_error', 
    //     modalActiveClass: 'modal_active',
    //     url: 'mailer/advertisement.php'
    // });

    new Swiper('.promo .swiper', {
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        grabCursor: true,
        loop: true,
        hashNavigation: {
            watchState: true,
        },
        navigation: {
            nextEl: ".promo .swiper-button-next",
            prevEl: ".promo .swiper-button-prev",
        },
        pagination: {
            el: '.promo .swiper-pagination',
            clickable: false,
        },
        speed: 700,
    });

    submitForm({
        formSelector: '.form_bonus', 
        formWrapperSelector: '.form_bonus .form__wrapper',
        formWrapperActiveClass: 'form__wrapper_active',
        inputSelector: '.form_bonus .form__input', 
        spinnerSelector: '.form_bonus .spinner-wrapper',
        spinnerActiveClass: 'spinner-wrapper_active',
        messageSuccessSelector: '.form_bonus .form__text_success',
        messageErrorSelector: '.form_bonus .form__text_error',
        messageActiveClass: 'form__text_active',
        url: 'mailer/bonus.php',
    });

    submitForm({
        formSelector: '.form_measurement', 
        formWrapperSelector: '.form_measurement .form__wrapper',
        formWrapperActiveClass: 'form__wrapper_active',
        inputSelector: '.form_measurement .form__input', 
        spinnerSelector: '.form_measurement .spinner-wrapper',
        spinnerActiveClass: 'spinner-wrapper_active',
        messageSuccessSelector: '.form_measurement .form__text_success',
        messageErrorSelector: '.form_measurement .form__text_error',
        messageActiveClass: 'form__text_active',
        url: 'mailer/measurement.php',
    });

   new Swiper('.feedback .swiper', {
        breakpoints: {
            320: {
              slidesPerView: 1,
            },
            992: {
              slidesPerView: 2,
              spaceBetween: 50
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 75
            }
        },
        loop: true,
        navigation: {
            nextEl: ".feedback .swiper-button-next",
            prevEl: ".feedback .swiper-button-prev",
        },
        speed: 700,
    });

    toggleModal({
        modalSelector: '.modal_feedback',
        modalActiveClass: 'modal_active',
        modalBtnSelector: '.feedback__btn'
    });

    submitForm({
        formSelector: '.form_feedback', 
        formWrapperSelector: '.modal_feedback .form__wrapper',
        formWrapperActiveClass: 'form__wrapper_active',
        inputSelector: '.form_feedback .form__input', 
        spinnerSelector: '.modal_feedback .spinner-wrapper',
        spinnerActiveClass: 'spinner-wrapper_active',
        messageSuccessSelector: '.modal_feedback .form__text_success',
        messageErrorSelector: '.modal_feedback .form__text_error',
        messageActiveClass: 'form__text_active',
        url: 'mailer/feedback.php',
    });

    initVideo('[data-video]');
});




