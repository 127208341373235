function submitForm({
    formSelector, 
    formWrapperSelector,
    formWrapperActiveClass,
    inputSelector, 
    spinnerSelector,
    spinnerActiveClass,
    messageSuccessSelector,
    messageErrorSelector,
    messageActiveClass,
    url
    }) {
    const form = document.querySelector(formSelector);
    const formWrapper = document.querySelector(formWrapperSelector);
    const inputs = document.querySelectorAll(inputSelector);
    const spinner = document.querySelector(spinnerSelector);
    const messageSuccess = document.querySelector(messageSuccessSelector);
    const messageError = document.querySelector(messageErrorSelector);
    
    const postData = async (url, data) => {
        let result = await fetch(url, {
            method: "POST",
            body: data
        });

        return await result.text();
    };

    const clearInputs = () => {
        inputs.forEach(input => {
            input.value = '';
        });
    };

    form.addEventListener('submit', (e) => {
        e.preventDefault();

        formWrapper.classList.remove(formWrapperActiveClass);
        spinner.classList.add(spinnerActiveClass);
        
        const formData = new FormData(form);
        postData(url, formData)
            .then(() => {
                messageSuccess.classList.add(messageActiveClass);
            })
            .catch(() => {
                messageError.classList.add(messageActiveClass);
            })
            .finally(() => {
                spinner.classList.remove(spinnerActiveClass);
                clearInputs();
            });
    });
};

export default submitForm;

