// убирает отступ справа при клике на модальное окно, чтобы когда открывалась модалка, экран не прыгал
function calcScroll() {
    let div = document.createElement('div');

    div.style.width = '50px';
    div.style.height = '50px';
    div.style.overflowY = 'scroll';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);

    let scrollWidth = div.offsetWidth - div.clientWidth // offsetWidth - полная ширина, clientWidth - паддинги + контент внутри, без прокрутки. таким образом scrollWidth - ширина самой прокрутки
    div.remove();

    return scrollWidth;
}

// overflow - если true, то страницу нельзя скроллить, пока модальное окно/форма открыта
// hasScroll - если true, то при открытии модального окна/формы к body добавляется справа отступ, чтобы не было дергания экрана
function openModal({
    modalSelector, 
    modalActiveClass,
    overflow = 'hidden',
    hasScroll = true,
    }) {
    const modal = document.querySelector(modalSelector);
    let scroll;

    if (hasScroll) {
        scroll = calcScroll();
    }

    modal.classList.add(modalActiveClass);
    document.body.style.overflow = overflow;
    document.body.style.marginRight = `${scroll}px`;
    document.body.querySelector('.header').style.width = `calc(100% - ${scroll}px)`;

    const tabContentsWrapper = document.querySelector('.header-dropdown .tabcontent__list-wrapper');
    const activeClass = 'tabcontent__list-wrapper_active';

    // то весь выпадающий список в хедере, если активен
    if (tabContentsWrapper.classList.contains(activeClass)) {
        // становится неактивным
        tabContentsWrapper.classList.remove(activeClass);
    }
}

// closeOnClickOnBg - если true, то модальное окно/форму можно закрыть по клику вне модального окна/формы
// automatically - если true, то окно принудельно закрывается. требуется при отправке формы
function closeModal({
    modalSelector, 
    modalActiveClass,
    closeOnClickOnBg = true,
    automatically = false
    }) {
    const modal = document.querySelector(modalSelector);

    function close() {
        modal.classList.remove(modalActiveClass);
        document.body.style.overflow = '';
        document.body.style.marginRight = 0;
        document.body.querySelector('.header').style.width = `100%`;
    }

    document.addEventListener('keydown', e => {
        if (e.code === "Escape" && modal.classList.contains(modalActiveClass)) {
            close();
        }
    });

    modal.addEventListener('click', e => {
        if (e.target.classList.contains('modal__close')) {
            close();
        }
    });

    if (closeOnClickOnBg) {
        document.addEventListener('click', e => {
            if (e.target === modal) {
                close();
            }
        });
    }

    if (automatically) {
        close();
    }
}

// closeOnClickOnBg - если true, то модальное окно/форму можно закрыть по клику вне модального окна/формы
// overflow - если 'hidden', то страницу нельзя скроллить, пока модальное окно/форма открыта
// hasScroll - если true, то при открытии модального окна/формы к body добавляется справа отступ, чтобы не было дергания экрана
// automatically - если true, то окно принудельно закрывается. требуется при отправке формы
function toggleModal({
    modalSelector,
    modalActiveClass,
    modalBtnSelector,
    closeOnClickOnBg, 
    overflow,
    hasScroll,
    automatically
    }) {
    document.querySelectorAll(modalBtnSelector).forEach(btn => {
        btn.addEventListener('click', () => {
            openModal({
                modalSelector, 
                modalActiveClass,
                overflow,
                hasScroll,
            });
            closeModal({
                modalSelector, 
                modalActiveClass,
                closeOnClickOnBg,
                automatically
            });
        });
    });
}

export {calcScroll, openModal, closeModal, toggleModal};